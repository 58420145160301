@import 'src/styles/colors.scss';

.allocator-container {
    background-color: $primaryColor;
    box-sizing: border-box;
    display: flex;
    height: 100%;
    width: 100%;
    padding: 12px 0 12px 20px;

    .allocator-container-landing {
        background-color: $allocatorComponentBackgroundLightColor;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 0 100px 0 80px;

        h1 {
            color: $black;
            font-weight: 400;
            margin: 0 0 50px;
        }

        .allocator-container-landing-upload {
            background-color: $allocatorComponentBackgroundColor;
            height: 300px;
            width: 100%;
            margin-bottom: 50px;
        }
    }

    .empty {
        margin: -12px -20px;
    }

    .allocator-left-container {
        min-width: 250px;
        height: 100%;
        display: flex;
        flex-direction: column;

        &::after {
            position: absolute;
            top: 0;
            left: 270px;
            content: " ";
            display: block;
            border-right: 1px solid $grey;
            height: calc(100% - 28px);
        }

        .allocator-left-container-upload {
            width: 220px;
            margin: 8px 0;
        }

        .allocator-left-container-list {
            overflow-y: auto;
            display: flex;
            flex-direction: column;
        }
    }

    .allocator-right-container {
        width: 100%;
        max-width: calc(100% - 250px);
        padding-top: 20px;

        .reports-container {
            background-color: $white;
            padding-left: 20px;
            padding-top: 5px;
            height: 100%;
        }
    }
}

.MuiButtonBase-root.MuiMenuItem-root {
    background-color: $white;
    min-height: 36px;
    min-width: 120px;
    @media (min-width: 600px) {
        min-height: 36px;
    }
}

.MuiButtonBase-root.MuiMenuItem-root.Mui-selected {
    background-color: $allocatorComponentBackgroundColor;
}

.MuiButtonBase-root.MuiMenuItem-root:hover {
    background-color: $allocatorComponentBackgroundLightColor;
}